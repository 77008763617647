<template>

  
  <div class="leftcenter">
    <div class="leftcenter-header">
      <div>
        <img class="leftcenter-headerimg" src="../../assets/1111.png" alt="">
  
        <span class="screen-title">实时空间拥挤度</span>
      </div>
      <div>
        <img class="screen-title-img" src="../../assets/right.png" alt="">
      </div>
    </div>
  	<div class="leftcenter-content">
  		<div class="leftcenter-content-box"  v-for="item in list" :key="item.id">
			<div class="leftcenter-content-box-header">
				<div class="leftcenter-content-box-header-left">
					健身房  <span>12/200</span>
				</div>
				<div class="leftcenter-content-box-header-right" :style="'color:'+item.color+';'">
					空闲
				</div>
			</div>
			<div class="leftcenter-content-box-footer" :style="'width:'+item.jd+'%;'+'background-color:'+item.color+';'"></div>
		</div>
  	</div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {
		
		list:[
				   {
					   jd:80,
					   color:"#B11F27"
				   },
				   {
				   			   jd:60,
				   			   color:"#B11F27"
				   },
				   {
				   			   jd:50,
				   			   color:"#507FDC"
				   },
				   {
				   			   jd:30,
				   			   color:"#29A397"
				   },
				   {
				   			   jd:10,
				   			   color:"#29A397"
				   }
		]

    }
  },

  mounted () {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.leftcenter {
  width: 100%;
  color: #ffffff;

  .leftcenter-header {
  	  width: 100%;
  	  display: flex;
  	  justify-content: space-between;
  	  align-items: center;
  }
  .leftcenter-title {
    font-size: 1.0rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 2rem;
    letter-spacing: 0.1rem;
  }
  .leftcenter-img {
  	  width: 3rem;
  	  height: 2rem;
  }
  .leftcenter-headerimg {
  	  width: 1.2rem;
  	  height: 1.2rem;
  }
}
.leftcenter-content {
	width: 100%;
	height: calc(100% - 2.5rem);
	
	.leftcenter-content-box {
		padding-top: 0.2rem;
		width: 100%;
		height: 2rem;
		margin-bottom: 0.2rem;
		.leftcenter-content-box-header {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			font-size: 0.8rem;
			color: #999;
			span {
				margin-left: 0.5rem;
			}
			.leftcenter-content-box-header-right {
				color: #7AF4FF;
			}
		}
	}
}

.leftcenter-content-box-footer {
				width: 100%;
				height: 0.5rem;
				
				background-color: #7AF4FF;
				border-radius: 0.25rem;
			}
</style>
