<template>
  <div class="right1">
    <right-top style="height: 33%;"></right-top>
    <right-center style="height: 33%;"></right-center>
    <right-bottom style="height: 33%;"></right-bottom>
  </div>
</template>

<script>
import rightTop from './rightTop.vue'
import rightCenter from './rightCenter.vue'
import rightBottom from './rightBottom.vue'
export default {
  components: {
    rightTop,
    rightCenter,
    rightBottom
  },
  data () {
    return {

    }
  },

  mounted () {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.right {

}
</style>
