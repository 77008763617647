<template>
  <div class="left1">
    <left-top style="height: 33%;"></left-top>
    <left-center style="height: 33%;"></left-center>
    <left-bottom style="height: 33%;"></left-bottom>
  </div>
</template>

<script>
import leftTop from './leftTop.vue'
import leftCenter from './leftCenter.vue'
import leftBottom from './leftBottom.vue'
export default {
  components: {
    leftTop,
    leftCenter,
    leftBottom
  },
  data () {
    return {

    }
  },

  mounted () {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.left {

}
</style>
