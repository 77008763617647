<template>
	<div class="centerleft">
		<div style="display: flex;justify-content: space-between;">
			<div>
				<img class="centerleft-headerimg" src="../../assets/1111.png" alt="">
				<span class="screen-title">自营空间预约多大占比率</span>
			</div>
			<div>
				<img class="screen-title-img" src="../../assets/right.png" alt="">
			</div>
		</div>
		<div class="centerleft-content"> 
			<div class="centerleft-content-left">
				<line-chart :options="optionB" height="100%" :span="height2" class="chartBox" />
			</div>
			<div class="centerleft-content-right">
				<div class="centerleft-content-right-box">
					<div class="centerleft-content-right-title">今日健身总预约</div>
					<div class="centerleft-content-right-sec">12.3%</div>
					<div class="centerleft-content-right-sec1">环比增长  12%  <img src="../../assets/shang.pic.jpg" alt=""></div>
				</div>
				
				<div class="centerleft-content-right-box">
					<div class="centerleft-content-right-title">昨日健身总预约</div>
					<div class="centerleft-content-right-sec">12.3%</div>
					<div class="centerleft-content-right-sec1">环比增长  12%  <img src="../../assets/xia.pic.jpg" alt=""></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import LineChart from "./dashboard/BarChartXY.vue"
	export default {
		components: {
			LineChart
		},
		data() {
			return {
				optionB:{}
			}
		},

		mounted() {
			this.getdata();
		},
		methods: {
			
			getdata() {
				const legendData = ["cpc", "转化率"]
				var data1=[1,2,3]
				var data2=[3,4,6]
				var data3=[]
				
				this.optionB = {
				  name: "",
				  toolbox: {
				    show: false,
				  },
				  showLabel: false,
				  xBoundaryGap: false,
				  legend: {
				    show: false,
				    // orient: 'vertical',
				    itemGap: 150,
				    data: legendData,
				    x: "center",
				    y: "top",
				  },
				  xData: ["222","222"],
				  xAxis: [
				    {
				      type: 'category',
				      axisTick: {
				        alignWithLabel: true
				      },
				      // prettier-ignore
				      data: ["11","1133"]
				    }
				  ],
				  yAxis: [
				    {
				      type: "value",
				      name: legendData[0],
				      position: "left",
				      alignTicks: true,
				      //
				      splitArea: {
				        show: false,
				      },
				    },
				    {
				      type: "value",
				      name: legendData[1],
				      position: "right",
				      alignTicks: true,
				      //
				      splitArea: {
				        show: false,
				      },
				    },
				    {
				      type: "value",
				      name: legendData[2],
				      position: "right",
				      // alignTicks: true,
				      // max: 100,
				      offset: 60,
				      //
				      splitArea: {
				        show: false,
				      },
				    },
				  ],
				  seriesData: [
				    {
				      type: "pie",
				      data: data1,
				    },
				    {
				      type: "pie",
				      yAxisIndex: 1,
				      data: data2,
				    },
				    {
				      type: "pie",
				      yAxisIndex: 2,
				      data: data3,
				    },
				  ],
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	.centerleft {
		width: 100%;
		color: #ffffff;
		padding-right: 1rem;
		box-sizing: border-box;

		.centerleft-title {
			font-size: 18px;
			font-family: SourceHanSansCN-Medium, SourceHanSansCN;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 27px;
			letter-spacing: 1px;
		}

		.leftcenter-img {
			width: 3rem;
			height: 2rem;
		}

		.centerleft-headerimg {
			width: 1.2rem;
			height: 1.2rem;
		}
	}

	.centerleft-content {
		width: 100%;
		height: calc(100% - 3rem);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		.centerleft-content-left {
			width: 50%;
		}
		.centerleft-content-right {
			width: 50%;
			
			.centerleft-content-right-box {
				width: 100%;
				color: #fff;
				padding-right: 0.2rem;
				box-sizing: border-box;
				margin-bottom: 0.4rem;
				.centerleft-content-right-title {
					// background-color: #2F5659;
					background: linear-gradient(to right, #2F5659, #131313);
					border-radius: 0.1rem;
					font-size: 0.8rem;
					padding: 0.1rem;
					box-sizing: border-box;
					
				}
				.centerleft-content-right-sec {
					font-size: 0.8rem;
					margin-top: 0.2rem;
				}
				.centerleft-content-right-sec1 {
					margin-top: 0.2rem;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					color: #999;
					font-size: 0.7rem;
					
				}
				img {
					width: 0.5rem;
					height: 0.5rem;
					margin-left: 0.3rem; 
				}
			}
		}
	}
</style>
