<template>
  <div class="centerb">
    <div class="centerimg"></div>
    <center-photo style=""></center-photo>
    <div class="centerb-footer">
      <center-left style="width:50%;height:100%;"></center-left>
      <center-right style="width:50%; height:100%;"></center-right>
    </div>
  </div>
</template>

<script>
import centerPhoto from './centerPhoto.vue'
import centerLeft from './centerLeft.vue'
import centerRight from './centerRight.vue'
export default {
  components: {
    centerLeft,
    centerRight,
    centerPhoto
  },
  data () {
    return {

    }
  },

  mounted () {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.centerb {
    position: relative;
}

.centerimg {
  width: 100% !important;
  height: 100%;
  background-image: url('../../assets/bgimagecenter.png');
  background-size: 100% 100%;
  top: -16%;
  left: -33%;
  position: relative;

}
.centerb-footer {
	width: 100%;
	height: calc(33.3vh - 2rem);
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	padding: 0 1rem;
	box-sizing: border-box;
	flex-direction: row;
}
</style>
