<template>
  <div class="centerphoto">
	  
	  <div class="centerphoto-content">
			<div class="centerphoto-content-title">实时人流量  <span id="">
				11:12:12
			</span></div>
			<div class="centerphoto-content-num">199203</div>
			<div class="centerphoto-content-sec">昨日游客量 23545  环比增长 12%  <img src="../../assets/shang.pic.jpg" alt=""></div>
	  </div>
     <div class="centerphoto-img"></div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {

    }
  },

  mounted () {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.centerphoto-img {
  width:74%;
  height: 53%;
  background: url('../../assets/bottom.png');
  background-size: 100% 100%;
  position: absolute;
  top: 7%;
  left: 13%;
  
}
.centerphoto-content {
	width: 16rem;
	height: 8rem;
	position: absolute;
	top: 5%;
	left: 3%;
	padding: 0.6rem;
	box-sizing: border-box;
	background-color: #7AF4FF;
	background: url('../../assets/images/numbg.png');
	background-size: 100% 100%;
	
	
	.centerphoto-content-title {
		font-size: 0.9rem;
		color: #7AF4FF;
		span {
			font-size: 0.8rem;
			margin-left: 0.1rem;
		}
	}
	.centerphoto-content-num {
		font-size: 2rem;
		color: #fff;
		text-align: center;
		margin-top: 1rem;
	}
	.centerphoto-content-sec {
		font-size: 0.6rem;
		margin-top: 0.6rem;
		color: #999;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		img {
			width: 0.5rem;
			height: 0.5rem;
			margin-left: 0.3rem; 
		}
	}
	
	
}
</style>
