<template>
  <div class="container">
    <div class="left">
      <div style="margin-right: 1rem;">
        <img style="width: 5rem;height: 2rem;" src="../../assets/logo.png" alt="">
      </div>
      <div>
        <p class="left-title">
          NFCC商业综合服务平台  
        </p>
        <p class="left-content">
          NFCC COMMERCIAL COMPREHENSIVE SERVICE PLATFORM
        </p>
      </div>
    </div>
    <div class="center">
      <div>
        <p class="center-date"> 2023-08-31</p>
        <p class="center-week"> 星期三 </p>
      </div>
      <div style="border-left: 1px solid #11F4FB;margin-left: 40px;height: 26px;opacity: 0.2;margin-top: 15px">

      </div>
      <div>
        <my-weather></my-weather>

      </div>
    </div>
  </div>
</template>

<script>
import myWeather from './myWeather.vue'
export default {
  components: {
    myWeather
  },
  data () {
    return {

    }
  },

  mounted () {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 6rem;
  padding-bottom: 0.5rem;
  position: relative;
  background: url('../../assets/line.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.left {
	width: 36%;
   display: flex;
   flex-direction: row;
  .left-title {
    font-size: 1.2rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 1.2rem;
    letter-spacing: 0.6rem;
  }

  .left-content {
    font-size: 0.2rem;
	font-size: 5rpx;
    color: #FFFFFF;
	margin-top: 0.3rem;
    line-height: 0.1rem;
  }
}

.center {
  // position: absolute;
  display: flex;
  // top: 0.6rem;
  // left: 36%;

  .center-date {
    font-size: 15px;
    font-family: Montserrat-Light, Montserrat;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 29px;
  }

  .center-week {
    font-size: 15px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 33px;
  }
}
</style>
