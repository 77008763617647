<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from "echarts";
require('echarts/lib/component/grid');
// require("echarts/theme/macarons"); // echarts theme
import resize from "./mixins/resize";

const animationDuration = 6000;

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "300px",
    },
    span: {
      type: Number,
      default: 12,
    },
    options: {
      customData: {},
      // 表名
      name: "",
      // 颜色值string[]
      color: [],
      // 数据分类string[]
      legendData: [],
      // x轴数据名称string[]
      xData: [],
      // y轴数据number[][]
      seriesData: [],
      // x轴数据名称倾斜角度number
      XRotate: 0,
      // 布局边界
      // grid: {
      //   left: '3%',
      //   right: '4%',
      //   bottom: '3%',
      //   containLabel: true
      // },
      grid: {},
      // x轴留间距
      xBoundaryGap: true,
      // 数据堆叠
      stack: false,
      // 显示值
      showLabel: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    option: function () {
      const {
        customData = {},
        color = [
          "#2d8cf0",
          "#13ce66",
          "#e6a23c",
          "#f56c6c",
          "#16afcc",
          "#b235e6",

          "#ff4d4f",
          "#ffec3d",
          "#bae637",
          "#36cfc9",
          "#ffff29",
          "#f759ab",
          "#ffa940",
          "#ff3029",
        ],
        name = "",
        title = {
          text: name,
          subtext: "",
        },
        toolbox = {
          show: true,
          feature: {
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            // restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        tooltip = {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legendData = [],
        legend = {
          // orient: 'vertical',
          data: legendData,
          // x: 'right',
          // y: 'bottom',
        },
        labelPosition = "top",
        xData = [],
        seriesData = [],
        XRotate = 0,
        grid = {
          left: "4%",
          right: "1%",
          bottom: "0%",
          containLabel: true,
        },
        xBoundaryGap = true,
        stack = false,
        showLabel = true,
        xAxis = [
          {
            type: "category",
            boundaryGap: xBoundaryGap,
            data: xData,
            axisLabel: {
              rotate: XRotate,
            },
          },
        ],
        yAxis = [
          {
            type: "value",
            // splitLine: false,
            // splitArea: {
            //   show: false
            // }
          },
        ],
      } = this.options;
      let series = [];
      if (seriesData && seriesData.length) {
        for (let i = 0; i < seriesData.length; i++) {
          let obj = {
            name: legendData[i],
            type: seriesData[i].type ? seriesData[i].type : "bar",
             barGap:'0%', 
            barMaxWidth: seriesData[i].barMaxWidth || 10,
            itemStyle: seriesData[i].itemStyle || {},
            label: {
              show: showLabel,
              position: labelPosition,
            },
			barCategoryGap:'0%',
            smooth: true,
            yAxisIndex: seriesData[i].yAxisIndex || 0,
            data: seriesData[i].data || seriesData[i],
          };
          if (stack) {
            obj.stack = "堆叠";
          }
          // if (showLabel) {
          //   obj.label = {
          //     show: true,
          //     position: "insideTop",
          //   };
          // }
          series.push(obj);
        }
        return {
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          title,
          tooltip,
          legend,
          grid,
          toolbox,
          calculable: true,
          xAxis,
          yAxis,
          series,
          color,
        };
      } else {
        return {...customData, color};
      }
    },
  },
  watch: {
    options: {
      handler: function (newVal) {
        this.chart.setOption(this.option);
      },
      deep: true,
      // immediate: true
    },
    span: {
      handler: function (newVal) {
        setTimeout(() => {
          this.chart.resize();
        }, 300);
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.initChart();
      // this.chart.on('magictypechanged', e => {
      //   console.log(e)
      //   if(e.currentType == 'bar') {
      //     this.option.xAxis[0].boundaryGap = true
      //   } else {
      //     this.option.xAxis[0].boundaryGap = true
      //   }
      //   // this.chart.setOption(this.option);
      // })
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption(this.option);
    },
  },
};
</script>
