<template>
  
  <div class="lefttop">
    <div class="lefttop-header">
      <div>
        <img class="lefttop-headerimg" src="../../assets/1111.png" alt="">
        <span class="screen-title">平台用户分析</span>
      </div>
      <div>
        <img class="screen-title-img" src="../../assets/right.png" alt="">
      </div>
    </div>
  	<div class="lefttop-content">
  		<div class="lefttop-content-header">
			<div class="lefttop-content-header-left">
				<div class="lefttop-content-header-left-title">入驻企业数量</div>
				<div class="lefttop-content-header-left-num">232343</div>
			</div>
			<div class="lefttop-content-header-left">
				<div class="lefttop-content-header-left-title">入驻商家数量</div>
				<div class="lefttop-content-header-left-num">232343</div>
			</div>
		</div>
		
		<div class="lefttop-content-footer">
			<line-chart :options="optionB" height="100%" :span="height2" class="chartBox" />
			
		</div>
  	</div>
  </div>
</template>

<script>
	import LineChart from "./dashboard/BarChartXY.vue";
	
export default {
  components: {
LineChart
  },
  data () {
    return {
optionB:{}
    }
  },

  mounted () {
this.getdata()
  },
  methods: {
getdata() {
				const legendData = ["平台用户数量分析"]
				var data1=[1,2,3]
				
				this.optionB = {
				  name: "",
				  toolbox: {
				    show: false,
				  },
				  showLabel: false,
				  xBoundaryGap: false,
				  legend: {
				    show: false,
				    // orient: 'vertical',
				    itemGap: 150,
				    // data: legendData,
				    x: "center",
				    y: "top",
				  },
				  xData: ["222","222"],
				  xAxis: [
				    {
				      type: 'category',
				      axisTick: {
				        alignWithLabel: true
				      },
				      // prettier-ignore
				      data: ["8点","9点","10点"]
				    }
				  ],
				  yAxis: [
				    {
				      type: "value",
				      name: legendData[0],
				      position: "left",
				      alignTicks: true,
				      //
				      splitArea: {
				        show: false,
				      },
				    },
				    {
				      type: "value",
				      name: legendData[1],
				      position: "right",
				      alignTicks: true,
				      //
				      splitArea: {
				        show: false,
				      },
				    },
				    {
				      type: "value",
				      name: legendData[2],
				      position: "right",
				      // alignTicks: true,
				      // max: 100,
				      offset: 60,
				      //
				      splitArea: {
				        show: false,
				      },
				    },
				  ],
				  seriesData: [
				    {
				      type: "bar",
				      data: data1,
				    }
				  ],
				}
			}
  },
}
</script>

<style lang="scss" scoped>
.lefttop {
  width: 100%;
  color: #ffffff;
  position: relative;
 
  .lefttop-header {
	  width: 100%;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  position: absolute;
	  top: -2.5rem;
	  right: 0;
  }
  .lefttop-title {
    font-size: 1.0rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 2rem;
    letter-spacing: 0.1rem;
  }
  .lefttop-img {
	  width: 3rem;
	  height: 2rem;
  }
  .lefttop-headerimg {
  	  width: 1.2rem;
  	  height: 1.2rem;
  }
}

.lefttop-content {
	width: 100%;
	height: 100%;
	.lefttop-content-header {
		width: 100%;
		height: 35%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		
		.lefttop-content-header-left {
			width: calc(50% - 0.6rem);
			height: 100%;
			background: url('../../assets/images/numbg.png');
			background-size: 100% 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.lefttop-content-header-left-title {
				font-size: 0.9rem;
				color: #fff;
			}
			.lefttop-content-header-left-num {
				font-size: 1.2rem;
				color: #7AF4FF;
				font-weight: bold;
				padding: 0.4rem 1rem;
				margin-top: 0.2rem;
				background-color: #396266;
				border-radius: 0.1rem;
			}
		}
	}
}

.lefttop-content-footer {
	width: 100%;
	height: 65%;
}

</style>
