<template>
  <div class="lefttop">
    <div class="lefttop-header">
      <div>
        <img class="lefttop-headerimg" src="../../assets/1111.png" alt="">
        <span class="screen-title">实时车流量</span>
      </div>
      <div>
        <img class="screen-title-img" src="../../assets/right.png" alt="">
      </div>
    </div>
	<div class="lefttop-content">
		<div class="lefttop-content-left">
			<div class="lefttop-content-left-img">
				<img src="../../assets/headstock.png" alt="">
			</div>
			<div class="lefttop-content-left-title">今日到访</div>
			<div class="lefttop-content-left-num">263</div>
		</div>
		<div class="lefttop-content-left">
			<div class="lefttop-content-left-img">
				<img src="../../assets/tailstock.png" alt="">
			</div>
			<div class="lefttop-content-left-title">今日返程</div>
			<div class="lefttop-content-left-num">263</div>
		</div>
	</div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {

    }
  },

  mounted () {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.lefttop {
  width: 100%;
  color: #ffffff;
  .lefttop-header {
	  width: 100%;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
  }
  .lefttop-title {
    font-size: 1.0rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 2rem;
    letter-spacing: 0.1rem;
  }
  .lefttop-img {
	  width: 3rem;
	  height: 2rem;
  }
  .lefttop-headerimg {
  	  width: 1.2rem;
  	  height: 1.2rem;
  }
}

.lefttop-content {
	width: 100%;
	height: calc(100% - 2.5rem);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	
	.lefttop-content-left {
		padding: 1rem;
		height: 100%;
		box-sizing: border-box;
		width: calc(50% - 0rem);
		
		.lefttop-content-left-img {
			width: 100%;
			height: 70%;
			padding: 0.2rem;
			box-sizing: border-box;
			border: 1px dashed #7af4ff;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.lefttop-content-left-title {
			font-size: 0.8rem;
			margin-top: 0.3rem;
			text-align: center;
			color: #999;
		}
		.lefttop-content-left-num {
			font-size: 1.2rem;
			margin-top: 0.3rem;
			text-align: center;
			color: #7af4ff;
		}
	}
	
}
</style>
