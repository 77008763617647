<template>
  <div class="bgcolor">
    <Top></Top>
    <div class="main">
      <left style="width: 24%;height: 100%;"></left>
      <Center style="width: 52%;height: 100%;"></Center>
      <right style="width: 24%;height: 100%;"></right>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin"
import Top from "./top.vue"
import Center from "./center.vue"
import left from './left.vue'
import right from './right.vue'
export default {
  mixins: [drawMixin],
  components: {
    Top,
    Center,
    left,
    right,
  },
  data () {
    return {

    }
  },

  mounted () {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.bgcolor {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/bgimage.png');
  padding: 0 2rem 0 2rem;
  background-size: 100% 100%;
}

.main {
	width: 100%;
	height: calc(100% - 6rem);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
</style>
