<template>
    <div id="he-plugin-simple"></div>
</template>

<script>

export default {
    name: 'myWeather',
    data() {
        return {
        }
    },
    mounted() {
        window.WIDGET = {
            "CONFIG": {
                "modules": "012",
                "background": "5",
                "tmpColor": "FFFFFF",
                "tmpSize": "21",
                "cityColor": "FFFFFF",
                "citySize": "21",
                "aqiColor": "FFFFFF",
                "aqiSize": "16",
                "weatherIconSize": "30",
                "alertIconSize": "16",
                "padding": "0.8rem 0.8rem 0.8rem 0.8rem",
                "shadow": "0",
                "language": "auto",
                "fixed": "false",
                "vertical": "top",
                "horizontal": "left",
                "key": "9941139b8ca5402f833966d3f58b8a26"
            }
        }
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0'
        document.getElementsByTagName('head')[0].appendChild(script)
    }
}
</script>

<style lang="less" scoped>
</style>
