<template>
	<div class="leftcenter">
		<div class="leftcenter-header">
			<div>
				<img class="leftcenter-headerimg" src="../../assets/1111.png" alt="">

				<span class="screen-title">各入口流量</span>
			</div>
			<div>
				<img class="screen-title-img" src="../../assets/right.png" alt="">
			</div>
		</div>
		<div class="leftcenter-content">
			<line-chart :options="optionB" height="100%" :span="height2" class="chartBox" />
		</div>
	</div>
</template>

<script>
	
	import LineChart from "./dashboard/BarChartXY.vue";
	export default {
		
		components: {
			LineChart
		},
		data() {
			return {
				optionB: {}
			}
		},

		mounted() {
			this.getdata()
		},
		methods: {
			getdata() {
				const legendData = ["到访", "返程"]
				var data1=[1,2,3]
				var data2=[3,4,6]
				
				this.optionB = {
				  name: "",
				  toolbox: {
				    show: false,
				  },
				  showLabel: false,
				  xBoundaryGap: false,
				  legend: {
				    show: false,
				    // orient: 'vertical',
				    itemGap: 150,
				    // data: legendData,
				    x: "center",
				    y: "top",
				  },
				  xData: ["222","222"],
				  xAxis: [
				    {
				      type: 'category',
				      axisTick: {
				        alignWithLabel: true
				      },
				      // prettier-ignore
				      data: ["8点","9点","10点"]
				    }
				  ],
				  yAxis: [
				    {
				      type: "value",
				      name: legendData[0],
				      position: "left",
				      alignTicks: true,
				      //
				      splitArea: {
				        show: false,
				      },
				    },
				    {
				      type: "value",
				      name: legendData[1],
				      position: "right",
				      alignTicks: true,
				      //
				      splitArea: {
				        show: false,
				      },
				    },
				    {
				      type: "value",
				      name: legendData[2],
				      position: "right",
				      // alignTicks: true,
				      // max: 100,
				      offset: 60,
				      //
				      splitArea: {
				        show: false,
				      },
				    },
				  ],
				  seriesData: [
				    {
				      type: "bar",
				      data: data1,
				    },
				    {
				      type: "bar",
				      yAxisIndex: 1,
				      data: data2,
				    }
				  ],
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	.leftcenter {
		width: 100%;
		color: #ffffff;

		.leftcenter-header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.leftcenter-title {
			font-size: 1.0rem;
			font-family: SourceHanSansCN-Medium, SourceHanSansCN;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 2rem;
			letter-spacing: 0.1rem;
		}

		.leftcenter-img {
			width: 3rem;
			height: 2rem;
		}

		.leftcenter-headerimg {
			width: 1.2rem;
			height: 1.2rem;
		}
	}

	.leftcenter-content {
		width: 400px;
		height: calc(100% - 2.5rem);
	}
</style>
