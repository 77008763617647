<template>
  
  <div class="leftbottom">
    <div class="leftbottom-header">
      <div>
  		<img class="leftbottom-headerimg" src="../../assets/1111.png" alt="">
        <span class="screen-title">入驻企业人员规模排行</span>
      </div>
      <div>
        <img class="screen-title-img" src="../../assets/right.png" alt="">
      </div>
    </div>
  	<div class="leftbottom-content">
  		<table class="leftbottom-table" style="width: 100%; font-size:0.9rem; ">
  		  <thead>
  		    <tr class="leftbottom-thead-tr">
  		      <th>企业名称</th>
  		      <th>员工数</th>
  		      <th>类型</th>
  			  <th>排名</th>
  		    </tr>
  		  </thead>
  		  <tbody style="width: 100%;">
  		    <tr  class="leftbottom-tbody-tr" v-for="item in 5" :key="item.id">
  		      <td style="width: 40%">111</td>
  		      <td style="width: 20%">22</td>
  		      <td style="width: 20%">33</td>
  			  <td class="leftbottom-tbody-td" style="width: 20%">
  				  11
  			  </td>
  		    </tr>
  		  </tbody>
  		</table>
  	</div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {

    }
  },

  mounted () {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.leftbottom {
  width: 100%;
  color: #ffffff;

  .leftbottom-header {
  	  width: 100%;
  	  display: flex;
  	  justify-content: space-between;
  	  align-items: center;
  }
  .leftbottom-title {
    font-size: 1rem;
	
	
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 2rem;
    letter-spacing: 0.1rem;
  }
  .leftbottom-img {
  	  width: 3rem;
  	  height: 2rem;
  }
  .leftbottom-headerimg {
	  width: 1.2rem;
	  height: 1.2rem;
  }
}

.leftbottom-content {
	width: 100%;
	height: calc(100% - 2.5rem);
	padding: 0.5rem 0.3rem 0.5rem 1rem;
	box-sizing: border-box;
	.leftbottom-table {
		width: 100%;
		font-size: 0.9rem;
		text-align: left;
		
		.leftbottom-thead-tr {
			color: #999;
			height: 1.6rem;
		}
		
		.leftbottom-tbody-tr {
			width: 100%;
			color: #fff;
		    height: 1.6rem;
		}
		
		.leftbottom-tbody-td {
			width: 30%;
			color: #7AF4FF;
		}
		
		.leftbottom-tbody-process {
			width: 28%;
			progress {
				width: 100%;
			}
		}
	}
}
</style>
